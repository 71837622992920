@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply font-co-primary max-w-full overflow-x-hidden scroll-smooth bg-co-alto text-black;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .btn--flamingo {
    @apply btn--base btn--pseudo bg-co-flamingo text-white;
  }

  .btn--fuscous-gray {
    @apply btn--base btn--pseudo bg-co-fuscous-gray text-white;
  }

  .title {
    @apply title--base text-5xl md:text-6xl sm:text-7xl lg:text-8xl xl:text-[125px] text-white;
  }

  .title--sub {
    @apply title--base text-3xl sm:text-4xl lg:text-5xl xl:text-6xl text-white;
  }

  .title--alt {
    @apply title--base text-2xl lg:text-3xl text-co-fuscous-gray;
  }

  .section {
    @apply py-12 lg:py-24;
  }

  .content {
    @apply content--base content--pseudo content--paragraphs content--headings;
  }

  .element--space {
    @apply mb-3 xs:mb-4 sm:mb-8 lg:mb-10;
  }

  .box--shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .block--shadow {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }

  .block--gradient {
    background: linear-gradient(270deg, #f5f5f5 0%, rgba(245, 245, 245, 0.25) 100%);
  }
}

@layer utilities {
  .btn--pseudo {
    @apply transition-all disabled:opacity-50 [&:not([disabled])]:hover:scale-105 [&:not([disabled])]:focus:scale-95 [&:not([disabled])]:hover:bg-co-flamingo [&:not([disabled])]:hover:underline;
  }

  .btn--base {
    @apply py-2 lg:py-3 px-5 lg:px-8 rounded-full inline-flex font-bold uppercase text-center;
  }

  .title--base {
    @apply uppercase font-black;
  }

  .flex--center {
    @apply flex items-center justify-center;
  }

  .content--pseudo {
    @apply children-a:underline children-a:text-co-flamingo hover:children-a:opacity-50;
  }

  .content--base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-strong:font-bold font-normal;
  }

  .content--paragraphs {
    @apply children-p:leading-7 lg:children-p:leading-8 lg:children-p:text-lg children-p:mb-6 lg:children-p:mb-8 children-p:font-normal children-p:text-black;
  }

  .content--headings {
    @apply children-headings:mb-3 lg:children-headings:mb-4 children-h1:mb-5 lg:children-h1:mb-6 children-h1:text-3xl children-h2:text-2xl children-h3:text-xl children-headings:font-bold children-headings:text-black/75;
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-co-flamingo h-2 rounded-r-full;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-duuf-submit > button {
  @apply btn--flamingo;
}

.form-duuf-group {
  @apply mb-5 relative;
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 bg-white/50 flex--center;
}

.form-duuf-label {
  @apply block title--base mb-1 text-white;
}

.form-duuf-error {
  @apply absolute right-0 top-0 text-xs text-red-400;
}

.form-duuf-group-consent .form-duuf-error {
  @apply relative mb-1;
}

.form-duuf-input {
  @apply rounded-full;
}

.form-duuf-textarea {
  @apply rounded-2xl min-h-[175px];
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply text-black py-3 px-5 w-full;
}

.form-duuf-group-consent span {
  @apply ml-2 text-sm;
}

.form-duuf-group-consent span a {
  @apply underline;
}

.form-duuf-checkbox {
  @apply w-5 h-5;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply btn--fuscous-gray py-1 px-2;
}

.form-duuf-group-submit {
  @apply pt-4;
}

/* Custom */

[aria-current='page'] .active-dot {
  @apply block;
}
